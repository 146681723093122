
import { Component, Vue } from 'vue-property-decorator';
import { getCategory, delCategory, addCategory, updateCategory } from '../api/ApiPath'
interface Category {
  category_id?: number;
  category_name: string;
  category_pid: number;
  category_level: number;
  children?: Category[];
}

@Component
export default class AdminCategory extends Vue {
  tableData = [];
  tableData1 = [];
  editDialogVisible: boolean = false;
  editedCategory: Category = {
    category_id: null,
    category_name: "",
    category_pid: 0,
    category_level: 0
  }


  init() {
    this.tableData = [];
    const categoryMap = new Map(); // 用于快速查找父类别    
    getCategory((res) => {
      const { category, status, msg } = res;
      if (status === 200) {
        this.tableData1 = category;
        this.tableData1.forEach(item => {
          const category = {
            category_id: item.category_id,
            category_name: item.category_name,
            category_pid: item.category_pid,
            category_level: item.category_level,
            children: []
          };
          if (item.category_pid === 0) {
            // 顶级类别
            this.tableData.push(category);
          } else {
            // 子类别
            const parentCategory: Category = categoryMap.get(item.category_pid);
            if (parentCategory && parentCategory.children) {
              parentCategory.children.push(category);
            }
          }
          categoryMap.set(item.category_id, category);
        });
      }
    }, (err) => { this.$message.error(err) })
  }

  allowDrop(draggingNode: any, dropNode: any, type: any): boolean {
    // 只允许在非根节点之间移动
    // return type === 'inner' && dropNode.level !== 0 
    return (draggingNode.level === 1 && dropNode.level === 1 && draggingNode.parent === dropNode.parent)
  }

  handleDragEnd(): void {
    // 拖拽结束时的处理逻辑
  }

  handleDrop(draggingNode: any, dropNode: any, dropType: any): void {
    // 处理拖放操作
    if (dropType === 'inner') {
      // 将拖拽节点作为子节点添加到目标节点
      draggingNode.data.children.push(draggingNode.data);
      // 从原父节点中移除拖拽节点
      const index = draggingNode.parent.data.children.findIndex((node: Category) => node.category_id === draggingNode.data.id);
      draggingNode.parent.data.children.splice(index, 1);
    } else if (dropType === 'before' || dropType === 'after') {
      // 将拖拽节点插入到目标节点的同级节点中
      const parentChildren = draggingNode.parent.data.children;
      const dropIndex = parentChildren.findIndex((node: Category) => node.category_id === dropNode.data.id);
      const dragIndex = parentChildren.findIndex((node: Category) => node.category_id === draggingNode.data.id);
      // 重新排序节点
      parentChildren.splice(dragIndex, 1);
      parentChildren.splice(dropIndex, 0, draggingNode.data);
    }
  }
  // 添加子类别   
  addChild(node: any): void {
    if (node.data.category_level === 0) {
      const newNode: Category = { category_name: '新子类别1', children: [], category_pid: node.data.category_id, category_level: 1 };
      addCategory(newNode, (res) => {
        this.init();
        let { status, msg } = res;
        this.$message.success(msg)
      }, (err) => {
        this.$message.error(err)
      })
      if (!node.children) {
        this.$set(node, "children", [])
      }
      // node.data.children.push(newNode);
    }
  }
  // 编辑类别 // TODO: 实现编辑逻辑 
  editCategory(node: any): void {
    this.editDialogVisible = true;
    this.editedCategory = node.data
    this.editedCategory.category_name = node.data.category_name;
  }
  deleteCategory(node: any): void { // 删除类别 
    if (node.data.category_level != 0) {
      this.$confirm('确定删除该目录？', '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCategory(node.data, (res) => {
          this.init()
          let { status, msg } = res
          this.$message.success(msg)
        }, (err) => { this.$message.error(err) })
      })
    }
  }
  // Save edited category
  saveEdit() {
    updateCategory(this.editedCategory, (res) => {
      this.init();
      let { status, msg } = res
      this.$message.success(msg);
    }, (err) => { this.$message.error(err) }
    )
    const node = this.findNodeById(this.tableData, this.editedCategory.category_id);
    if (node) {
      node.name = this.editedCategory.category_name;
    }
    this.cancelEdit();
  }
  cancelEdit() {
    this.editDialogVisible = false;
  }
  findNodeById(nodes: any, id: number): any | null {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node.id === id) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        const foundNode = this.findNodeById(node.children, id);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  }

  created() {
    this.init()
  }
}
