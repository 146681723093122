
import { Vue, Component } from "vue-property-decorator";
import FileUpload from "@/components/FileUpload.vue";
import { getproduct, getPictureByProductid } from "../api/ApiPath";
@Component({ components: { FileUpload } })
export default class ProductManager extends Vue {
	products = []; // 产品列表
	fileLoad = [];
	dialogVisible = false; // 对话框状态
	currentProduct = {
		product_id: null,
		product_name: "",
		product_price: 0,
		images: [], // 多张图片路径
	};

	mounted() {
		// this.fetchProducts(); // 初始化加载产品列表
		getproduct((res: any) => {
			console.log(res);
			this.products = res.product
		},
			(err: any) => console.log(err))
	}

	async fetchProducts() {

	}

	openDialog(product) {
		this.dialogVisible = true;
		if (product) {
			this.currentProduct = { ...product };
			getPictureByProductid(product, (res: any) => {
				console.log(res.data);
				console.log(typeof res.data , res.data instanceof Array)
				if (Array.isArray(res.data)) {
					setTimeout(() => {
					this.fileLoad = [...res.data] || [];},100)
				} else {
					console.error("res.data is not an array");
				}
			},(err: any) => console.log(err))
		} else {
			this.currentProduct = { product_id: null, product_name: "", product_price: 0, images: [] };
		}
	}

	// 上传图片成功，添加到 currentProduct.images
	handleUploadSuccess(updatedFileList:any) {
		this.currentProduct.images = [
			updatedFileList.map((item:any) => {
				if (typeof item.response === "object" && item.response !== null) {
					return {
						name: item.response.data.name,
						url: `${process.env.VUE_APP_API_URL}/` + item.response.data.name,
					};
				} else {
					return { name: item.name, url: item.url };
				}
			}),
		]
	}

	// 删除图片，更新 currentProduct.images
	handleRemove(file:any) {
		const index = this.currentProduct.images.findIndex((url) => url === file.url);
		if (index !== -1) {
			this.currentProduct.images.splice(index, 1); // 删除对应图片
		}
	}

	saveProduct() {

	console.log(this.currentProduct.images)
	console.log(this.currentProduct)
		// const res = await this.$http.post("http://localhost:3000/api/products", this.currentProduct);
		// if (res.data.success) {
		// 	this.dialogVisible = false;
		// 	await this.fetchProducts(); // 刷新列表
		// }
	}

	async deleteProduct(id) {
		// await this.$http.delete(`http://localhost:3000/api/products/${id}`);
		// await this.fetchProducts(); // 刷新列表
	}
}
