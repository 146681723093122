
import { Component, Vue } from "vue-property-decorator";
import { getCategory } from "../api/ApiPath";
@Component
export default class left extends Vue {
  cate = [];

  transformToNestedArray = (categories: any) => {
    const categoryMap = {};
    // 先将所有类别放入一个 map 中
    categories.forEach((category) => {
      category.children = []; // 初始化 children 属性
      categoryMap[category.category_id] = category;
    });
    // 构建父子关系
    const nestedArray = [];
    categories.forEach((category) => {
      if (category.category_pid === 0) {
        // 如果是根类别，直接加入到结果数组
        nestedArray.push(category);
      } else if (categoryMap[category.category_pid]) {
        // 否则找到父类别，加入到其 children 中
        categoryMap[category.category_pid].children.push(category);
      }
    });
    return nestedArray;
  };

  navigateAndRefresh(category_id:any){
    this.$router.replace({
      path: '/show',query: { categoryId: category_id } 
    }).then(()=>window.location.reload()).catch((err)=>{
      if(err.name !== 'NavigationDuplicated')console.error(err)})
  }
  mounted() {
    getCategory(
      (res: any) => {
        this.cate = this.transformToNestedArray(res.category);
      },
      (err: any) => {
        console.error(err);
      }
    );
  }
}
