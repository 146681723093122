
import {Component, Vue } from 'vue-property-decorator'
import { saveRegist } from "../api/ApiPath";
import { Message } from 'element-ui';
@Component
export default class Login extends Vue{
  registForm ={
    username: "",
    password: "",
    repassword: "",
    agree: false
  }
  regists() {
    const { username, password, repassword } = this.registForm;

    // Check username length
    if (username.length < 6) {
      Message.error(this.$t('login.usernameTooShort').toString());
      return;
    }

    // Check password length
    if (password.length < 6) {
      Message.error(this.$t('login.passwordTooShort').toString());
      return;
}

    if (this.registForm.password !== this.registForm.repassword) {
      // Display error message using el-message
      Message.error(this.$t('login.passwordMismatch').toString());
      return;
    }
     if (this.registForm.agree  == false) {
      Message.error(this.$t('login.agree').toString());
      return;
    }
    // Success message
    Message.success(this.$t('login.registrationSuccess').toString());
    // Registration logic here
    saveRegist(
        this.registForm,
        (res:any) => {
          const { msg, data, status } = res;
          if (status === 200) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
        },
        (err:any) => {
          this.$message.error(err);
        }
      );
  }
}


