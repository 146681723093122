
import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import banner from '@/components/banner.vue'

import foot from '@/components/foot.vue'
import sidebar from '@/components/sidebar.vue'
import {getUserInfo} from './api/ApiPath'
@Component({
  components:{
    banner, foot, sidebar
  }
})
export default class App extends Vue{
  user = ""
  getToken(){
    const token = localStorage.getItem('token')
    try{
    this.user = token? JSON.parse(token) : null
    }catch(e){ 
      console.error("Error parsing token: " + e);
      this.user = null;
    }
  }

  mounted(){
    this.getToken();
  }
}
