
// import Vue from 'vue'
import { Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class banner extends Vue{
  @Prop({ required: true }) // Prop 是必填项
  public user!: { userName: string; };

  logout(){
    this.$confirm("确认登出么").then(_=>{
    localStorage.setItem("token",null)
    this.user = null
    location.href='/'
    }).catch(_=>{

    })
  }
  searchKeyword = "";
  search(){
    this.$router.replace({
      path: '/show',query: { name: this.searchKeyword } 
    }).then(()=>window.location.reload()).catch((err)=>{
      if(err.name !== 'NavigationDuplicated')console.error(err)})
  }
  currentLang: 'zh' | 'ja' = 'ja'; // 默认语言
    flags = {
        zh: require('../assets/cn.svg'), // 中文国旗    
        ja: require('../assets/jp.svg') // 日文国旗
      }
    
    get currentFlag(): string {
        return this.flags[this.currentLang];
    }
    changeLanguage():void{
        // 切换语言
        this.currentLang = this.currentLang === 'zh' ? 'ja' : 'zh';
        this.$i18n.locale = this.currentLang; // 更新 i18n 语言
    }
}


