import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Trolley from '../views/Trolley.vue'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('../views/Error.vue')
  },
  {
    path:'/trolley',
    name: 'Trolley',
    // component: () => import('../views/Trolley.vue')
    component: Trolley
  },
  {
    path:'/detail',
    name: 'Detail',
    component: () => import('../views/Detail.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/regist',
    name: 'Regist',
    component: () => import('../views/Regist.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path:'/picture',
    name: 'Picture',
    component: () => import('../views/Picture.vue')
  },
  {
    path:'/show',
    name: 'Show',
    component: () => import('../views/Show.vue')
  },
  {
    path: '/admincategory',
    name: 'AdminCategory',
    component: () => import('../views/AdminCategory.vue')
  },{
    path: '/adminproduct',
    name: 'Adminproduct',
    component: () => import('../views/AdminProduct.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
