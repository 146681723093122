import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.use(VueI18n);

Vue.config.productionTip = false

// 创建 vue-i18n 实例
const i18n = new VueI18n({
  locale: 'ja', // 设置默认语言
  fallbackLocale: 'ja', // 设置回退语言
  messages: { // 设置语言包
    'zh': require('./locales/zh.json'),
    'ja': require('./locales/ja.json')
  }
});

const vm = new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default vm