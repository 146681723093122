
import { Component, Vue } from 'vue-property-decorator';
import left from '../components/left.vue';
import { getproductList,getPictureByProductid } from '../api/ApiPath'
@Component({
  components: { left }
})
export default class Show extends Vue {
  // 商品数据（可替换为从后端获取的数据）
  products = ([]);
  // 搜索关键词
  searchKeyword = ('');

  // 当前页和每页大小
  currentPage: number = 1;
  pageSize: number = 6;

  // 计算当前页的商品数据
  get paginatedItems() {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.products.slice(start, end)
  }

  // 处理页码变化
  handlePageChange(page: number) {
    this.currentPage = page;
  }

  created() {
     const params = this.$route.query
    getproductList(params,(res:any) =>{
      this.products = res.product;
      this.products.map(item=>{
        getPictureByProductid({ product_id: item.product_id, isMain: 0 }, (res: any) => {
					const { status, data, msg } = res;
					if (status == 200) {
						item.product_picture = data[0].url;
					} else {
						console.log(msg);
					}
				}, (err: any) => {
					console.log(err);
				})
			})
    },(err:any)=>{console.error(err)})
  }
}

