
import {Component, Vue} from "vue-property-decorator"
@Component
export default class carousel extends Vue {
    items = [
        {title:"国際貿易・越境EC", contact:"・輸出入業務 ・通信販売事業（ネット通販・カタログ通販）",img:require("../assets/carousel/dianshang.png")},
        {title:"新規事業", contact:"・日用品雑貨卸売業・製品開発（国内・海外パートナーとOEM製品の共同開発）",img:require("../assets/carousel/shiye.png")},
        {title:"ソフトウェア開発", contact:"・技術力、提案力、コンサルティング力",img:require("../assets/carousel/jishu.png")}
    ]
}
