
import {Component, Vue } from 'vue-property-decorator'
import { login } from "../api/ApiPath";
@Component
export default class Login extends Vue{
  loginForm ={
    username: "",
    password: ""
  }
  logins() {
    localStorage.setItem("token", "");
    const { from } = this;
    if (this.loginForm.username == "" || this.loginForm.password == "") {
      this.$message.error("用户名或者密码必须填");
    } else {
      login(
        this.loginForm,
        (res:any) => {
          const { data, status, msg } = res;
          if (status === 200) {
            var token = JSON.stringify(res.data[0]);
            localStorage && localStorage.setItem("token", token);
            this.$message.success(msg);
            if (from) {
              location.href = from;
            } else {
              location.href = "/";
            }
          } else {
            this.$message.error(msg);
          }
        },
        (err:any) => {
          this.$message.error(err);
        }
      );
    }
  }
  from: string;
  mounted() {
    const { query } = this.$route;
    const { from } = query;
    if (this.from != undefined && this.from != "") {
      this.from = from.toString();
    } else {
      this.from = "";
    }
  }
}

